import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Tags from '../common/Tags';

const PostCardWide = ({ post }) => {
    const url = `/${post.slug}/`;
    
    return (
        <Link to={url} className="post-card-wide">
            <section className="post-card-excerpt">
                {post.tags && 
                    <div className="post-card-tags"> 
                        <span className="tag-list">
                            <Tags post={post} />
                        </span>
                        <span className="post-date">
                            {post.published_at}
                        </span>
                    </div>
                }
                <h2 className="post-card-title fs-3">{post.title}</h2>
                <p className="fs-5">{post.excerpt}</p>
            </section>
            <header className="post-card-header">
                {post.feature_image &&
                    <div
                        className="post-card-image"
                        style={{ backgroundImage: `url(${post.feature_image})` }}>
                    </div>
                }
            </header>
        </Link>
    )
}

PostCardWide.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        id: PropTypes.string,
        featured: PropTypes.bool,
        published_at: PropTypes.date,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
}

export default PostCardWide
