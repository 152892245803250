import React from 'react';
import PropTypes from 'prop-types';
import PostCardWide from '../common/PostCardWide';

const ProductUpdateFeed = ({ posts, tagToFilterBy }) => {
    // filter posts based on tag selected from TagFilterButtons
    const postsToShow = posts.filter(({ node }) => {
        if (tagToFilterBy === 'all') {
            return true;
        }
        const singleTags = node.tags.map((tag) => {
            const singleTagSlug = tag.slug;
            return singleTagSlug === tagToFilterBy;
        })
        const postsContainingSelectedTag = singleTags.some(post => post === true)
        return postsContainingSelectedTag;
    })

    return (
        <div className="product-update-post-feed">
            {postsToShow.length > 0 ?
                postsToShow.map(({ node }) => (
                    !node.featured
                        ? <PostCardWide key={node.id} post={node} />
                        : null
                ))
                : <p className="empty-post">No {tagToFilterBy === 'all' ? '' : tagToFilterBy} posts available</p>
            }
        </div>
    )
}
ProductUpdateFeed.propTypes = {
    posts: PropTypes.array,
    tagToFilterBy: PropTypes.string,
}

export default ProductUpdateFeed
