import React from 'react';
import PropTypes from 'prop-types';

const Tags = ({ post }) => {
    const allTags = post.tags;
    
    return (
        allTags.map((tag, i) => (
            <span
                key={tag + i}
                className={tag.slug}
            >
                {tag.name}
            </span>
        ))
    )
}

Tags.propTypes = {
    post: PropTypes.shape({
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
    }).isRequired,
}

export default Tags
