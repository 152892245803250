import React from 'react'
import PropTypes from 'prop-types'

const tagFilterButtonSpecs = [
    ['all', 'All'],
    ['prioritization', 'Prioritization'],
    ['feedback', 'Feedback'],
    ['roadmaps', 'Roadmaps'],
]

const TagFilters = ({ setTagToFilterBy, tagToFilterBy }) => (
    <div className="tag-filters">
        Topic:
        {tagFilterButtonSpecs.map(([tagSlug, tagTitle], i) => (
            <button
                onClick={() => setTagToFilterBy(tagSlug)}
                className={tagToFilterBy === tagSlug ? 'selected' : ''}
                key={i}
            >
                {tagTitle}
            </button>
        ))}
    </div>
)
TagFilters.propTypes = {
    setTagToFilterBy: PropTypes.func.isRequired,
    tagToFilterBy: PropTypes.string,
}

export default TagFilters