import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Layout } from '../components/common';
import { graphql } from 'gatsby';
import Pagination from '../components/common/Pagination';
import TagFilterButtons from '../components/home/TagFilterButtons';
import ProductUpdateFeed from '../components/post/ProductUpdateFeed';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const ProductUpdatesPage = ({ data, pageContext }) => {
    const [tagToFilterBy, setTagToFilterBy] = useState('all');
    const posts = data.allGhostPost.edges;

    return (
        <Layout>
            <div className="product-updates-page">
                <header className="header-decorations">
                    <div className="container">
                        <div>
                            <Link to="/"><MdKeyboardArrowLeft className="react-arrow-left" />Back to Learning Center</Link>
                            <h1>Product Updates</h1>
                            <p className="text-center">Make your Roadmunk experience even better with our latest (and greatest) features.</p>
                        </div>
                    </div>
                </header>

                <section className="product-update-post-feed">
                    <div className="post-feed-container">
                        <TagFilterButtons
                            setTagToFilterBy={setTagToFilterBy}
                            tagToFilterBy={tagToFilterBy}
                        />
                        <ProductUpdateFeed
                            posts={posts} 
                            tagToFilterBy={tagToFilterBy} 
                        />
                    </div>
                </section>
            </div>
            <Pagination pageContext={pageContext}/>
        </Layout>
    )
}

ProductUpdatesPage.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default ProductUpdatesPage

export const productUpdatePageQuery = graphql`
    query ProductUpdateTemplateQuery($skip: Int!, $limit: Int!) {
        allGhostPost(
            filter: {tags: {elemMatch: {slug: {eq: "product-updates"}}}},
            sort: {order: DESC, fields: published_at},
            skip: $skip,
            limit: $limit
        ) {
            edges {
                node {
                    slug
                    id
                    title
                    feature_image
                    tags {
                        name
                        slug
                    }
                    url
                    excerpt
                    published_at(formatString: "MMM D, YYYY")
                }
            }
        }
    }
`
